<template>
   <div id="homeDiv" class="d-flex flex-column">
      <div class="sectionWrapperNoTop">
         <h1 class="pageH1">Gem State Tracks are the go-to specialists in Track and Field surface painting</h1>
         <img class="col-sm-8 pageMainImg" alt="" src="../assets/resized/UnionMineOverhead-resized.webp" />
         <div class="paragraphMarginTop px-3 text-start">
            <span class="mainParagraphFontSize text-wrap">We are a small, extremely mobile company based in Boise,
               Idaho. We most commonly work
               in California, Washington, Nevada, and, of course, Idaho. Other frequent locations we visit include
               Montana
               and Utah</span>
         </div>
         <div class="paragraphMarginTop px-3 text-start">
            <span class="mainParagraphFontSize text-wrap">
               Our team, although small, consists of those who have over a decade of
               experience in track painting and construction and take pride in their work. No matter how big a job might
               be,
               we are willing to tackle it and bring promising results!
            </span>
         </div>
      </div>

      <div class="sectionWrapper sectionBGDark text-start">
         <div class="row">
            <div class="col-md-6 mt-3 homeSectionTextPadding">
               <span class="mainParagraphFontSize text-wrap">We specialize in meticulously crafting all the elements
                  that often
                  go
                  unnoticed by the general public, transforming paved or rubberized ovals into high-performance
                  competition
                  track and field surfaces
               </span>
            </div>
            <div class="col-md-6 mt-3">
               <img class="sectionImg" alt="" src="../assets/resized/LowryRepaint-resized.webp" />
            </div>
         </div>
         <div class="row mt-5">
            <div class="col-md-6 mt-3 mb-3">
               <img class="sectionImg" alt="" src="../assets/resized/Troy-resized.webp" />
            </div>
            <div class="col-md-6 mt-3 mb-3 homeSectionTextPadding">
               <span class="mainParagraphFontSize text-wrap">Straight lines, beautiful curves, and accurately measured
                  start lines, lane numbers, hurdle
                  marks, exchange zone marks, and school name/mascot lettering.
               </span>
               <br>
               <span class="mainParagraphFontSize text-wrap">
                  With over a decade of experience painting running tracks at middle schools, high schools, colleges,
                  and universities all over the country, we are the company to turn to for all your Track and Field
                  painting needs!
               </span>
            </div>
         </div>
      </div>

      <div class="mt-5 pb-5 d-flex flex-column justify-content-center align-items-center">
         <div class="p-3"><span class="mainParagraphFontSize">
               We are mobile and work fast<br>The Track in the following video was painted overnight and in another
               state
            </span>
         </div>
         <iframe id="youtubeIFrame"
            src="https://www.youtube-nocookie.com/embed/RtsK3HgZYBQ?si=nL8cPZuPRR6FLlCP&amp;start=40"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
   </div>
</template>

<script>
// @ is an alias to /src


export default {
   name: 'HomeView',

}
</script>
